<template>
  <v-row>
    <v-col cols="12" class="py-0  pa-5">
      <h1 class="font-weight-light mb-0">Create Company</h1>
      <span class="overline"
        >No Company is selected register Your company here</span
      >
    </v-col>

    <v-col cols="12" class="py-0">
      <v-row>
        <v-col xxs="12">
          <v-form ref="sendForm" v-model="valid" lazy-validation>
            <v-card class="mb-4 pa-5">
              <v-text-field
                v-model="getFreelancerCompany.companyName"
                label="Company Name"
                autocomplete="company"
                outlined
                dense
                :rules="[rules.required]"
              ></v-text-field>

              <vuetify-google-autocomplete
                id="map"
                :country="['ug']"
                v-bind:disabled="false"
                classname="form-control"
                prepend-inner-icon="place"
                label="Company Location"
                item-text="name"
                v-model="getFreelancerCompany.locationLabel"
                outlined
                dense
                v-on:placechanged="getAddressData"
                :rules="[rules.required]"
              >
              </vuetify-google-autocomplete>

              <v-text-field
                v-model="getFreelancerCompany.companyEmail"
                label="Company Email"
                autocomplete="email"
                outlined
                dense
                :rules="[rules.required]"
              ></v-text-field>

              <v-text-field
                v-model="getFreelancerCompany.companyWebsite"
                label="Company Website"
                autocomplete="website"
                outlined
                dense
                :rules="[rules.required]"
              ></v-text-field>

              <v-text-field
                v-model="getFreelancerCompany.companyPhone"
                label="Company Phone"
                autocomplete="phone"
                outlined
                dense
                :rules="[rules.required]"
              ></v-text-field>

              <v-textarea
                v-model="getFreelancerCompany.companyDescription"
                label="Company Description"
                rows="4"
                max-rows="4"
                outlined
                dense
                :rules="[rules.required]"
              />

              <v-file-input
                label="Company Avator"
                v-model="file"
                outlined
                dense
              ></v-file-input>
              <v-btn
                class="mb-1"
                @click="validateNewCompanyForm"
                variant="success"
                >Add Company</v-btn
              >
            </v-card>
          </v-form>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import Vue from "vue";
import firebase from "firebase";
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";

export default {
  template: "#page",

  data() {
    return {
      file: null,
      valid: false,
      getFreelancerCompany: {
        location: null,
      },
      rules: {
        required: (value) => !!value || "This field is required",
        email: (v) => /.+@.+\..+/.test(v) || "Must be a valid email",
      },
    };
  },
  beforeCreate() {
    this.$store.dispatch("fetchMyCompany");
  },

  computed: {
    ...mapState(["freelancerCompany"]),
    ...mapGetters(["messageAlert"]),
  },

  watch: {
    messageAlert(val) {
      if (val != null) {
        Vue.notify({
          group: "foo",
          title: val.title,
          text: val.body,
        });
        this.$router.push("/companies");
        this.$store.dispatch("fetchMyCompany");
      }
    },
  },

  methods: {
    validateNewCompanyForm() {
      if (this.$refs.sendForm.validate()) {
        // send it
        this.submitCompany();
      }
    },

    submitCompany() {
      var metadata = {
        contentType: "image/jpeg",
      };
      var goON = true;
      const context = this;

      if (context.file === null) {
        goON = false;
        this.$notify("error", "Registration Error", "Company Avator Required", {
          duration: 3000,
          permanent: false,
        });
      }

      if (goON) {
        // Create a root reference
        var storageRef = firebase.storage().ref();

        // Upload file and metadata to the object 'images/mountains.jpg'
        var uploadTask = storageRef
          .child("webimages/api/accounts/" + context.file.name)
          .put(context.file, metadata);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          function(snapshot) {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                console.log("Upload is paused");
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                console.log("Upload is running");
                break;
            }
          },
          function(error) {
            switch (error.code) {
              case "storage/unauthorized":
                // User doesn't have permission to access the object
                break;

              case "storage/canceled":
                // User canceled the upload
                break;

              case "storage/unknown":
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          },
          function() {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(function(downloadURL) {
                console.log("File available at", downloadURL);
                context.getFreelancerCompany.companyAvator = downloadURL;
                context.getFreelancerCompany.location = {};
                console.log(context.getFreelancerCompany);
                context.$store.dispatch(
                  "addNewCompany",
                  context.getFreelancerCompany
                );
              });
          }
        );
      }
    },

    getAddressData: function(addressData, placeResultData, id) {
      this.getFreelancerCompany.location = addressData;
    },
  },
  created() {
    console.log(this.getFreelancerCompany);
  },
};
</script>
